import React, { useContext, useEffect, useState } from 'react';
import SummaryApi from '../common';
import Context from '../context';
import displayTZSCurrency from '../helpers/DisplayCurrency';
import { MdDelete } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const Cart = () => {
    const [cartData, setCartData] = useState([]);
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(false);
    const context = useContext(Context);

    const loadingCart = new Array(context.cartProductCount).fill(null);

    const fetchCartData = async () => {
        const response = await fetch(SummaryApi.addToCartProductView.url, {
            method: SummaryApi.addToCartProductView.method,
            credentials: 'include',
            headers: {
                "content-type": 'application/json'
            }
        });
        const responseData = await response.json();

        if (responseData.success) {
            setCartData(responseData.data);
        }
    };

    const fetchUserData = async () => {
        const response = await fetch(SummaryApi.current_user.url, {
            method: SummaryApi.current_user.method,
            credentials: 'include'
        });
        const dataResponse = await response.json();

        if (dataResponse.success) {
            setUserData(dataResponse.data);
        } else if (dataResponse.error) {
            toast.error(dataResponse.message);
        }
    };

    const handleLoading = async () => {
        await fetchCartData();
    };

    useEffect(() => {
        setLoading(true);
        handleLoading();
        fetchUserData();
        setLoading(false);
    }, []);

    const increaseQty = async (id, qty) => {
        const response = await fetch(SummaryApi.updateCartProduct.url, {
            method: SummaryApi.updateCartProduct.method,
            credentials: 'include',
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({ quantity: qty + 1, _id: id })
        });
        const responseData = await response.json();

        if (responseData.success) {
            fetchCartData();
        }
    };

    const decreaseQty = async (id, qty) => {
        if (qty >= 2) {
            const response = await fetch(SummaryApi.updateCartProduct.url, {
                method: SummaryApi.updateCartProduct.method,
                credentials: 'include',
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({ quantity: qty - 1, _id: id })
            });
            const responseData = await response.json();

            if (responseData.success) {
                fetchCartData();
            }
        }
    };

    const deleteCartProduct = async (id) => {
        const response = await fetch(SummaryApi.deleteCartProduct.url, {
            method: SummaryApi.deleteCartProduct.method,
            credentials: 'include',
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({ _id: id })
        });
        const responseData = await response.json();

        if (responseData.success) {
            fetchCartData();
            context.fetchUserAddToCart();
        }
    };

    const totalQty = cartData.reduce((prev, curr) => prev + curr.quantity, 0);
    const totalPrice = cartData.reduce((prev, curr) => prev + (curr.quantity * curr?.productId?.sellingPrice), 0);

    const handlePayment = () => {
        const url = new URL("https://pay.tecfinex.co.tz/");

        const params = {
            first_name: userData?.name,
            last_name: userData?.name,
            email: userData?.email,
            phone_number: userData?.phone,
            amount: totalPrice,
        };

        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

        window.location.href = url;
    };

    return (
        <div className='container md:px-4 w-full max-w-[2560px] mx-auto'>
            <h2 className='bg-orange-400 text-white font-medium text-lg my-5 text-center'>My Cart</h2>
            <div className='text-center text-lg my-3'>
                {cartData.length === 0 && !loading && (
                    <>
                        <p className='bg-white py-5'>Your Cart is Empty!</p>
                        <Link to={"/"}>
                            <p className='bg-white py-0 font-bold text-orange-500'>Go Shopping</p>
                        </Link>
                    </>
                )}
            </div>
            <div className='flex flex-col lg:flex-row gap-10 lg:justify-between p-4'>
                {/* Cart Products */}
                <div className='w-full max-w-2xl'>
                    {loading ? (
                        loadingCart.map((el, index) => (
                            <div key={index} className='bg-slate-200 w-full h-32 my-2 border border-slate-300 animate-pulse rounded' />
                        ))
                    ) : (
                        cartData.map((product, index) => (
                            <div key={product?._id} className='bg-white w-full h-32 my-2 border border-slate-300 rounded grid grid-cols-[130px,1fr]'>
                                <div className='w-32 h-32 bg-slate-200'>
                                    <img src={product?.productId?.productImage[0]} className='w-full h-full object-scale-down mix-blend-multiply' />
                                </div>
                                <div className='px-4 py-2 relative'>
                                    {/* Delete Product from Cart */}
                                    <div onClick={() => deleteCartProduct(product?._id)} className='absolute right-0 text-red-600 rounded-full p-3 text-xl cursor-pointer hover:bg-red-600 hover:text-white' title='Delete Item from cart'>
                                        <MdDelete />
                                    </div>
                                    <h2 className='text-lg lg:text-xl'>{product?.productId?.productName}</h2>
                                    <p className='text-orange-400'>{product?.productId?.category}</p>
                                    <div className='flex items-center justify-between'>
                                        <p className='text-red-600 font-medium text-lg md:text-xs sm:text-xs'>{displayTZSCurrency(product?.productId?.sellingPrice)}</p>
                                        <p className='text-slate-600 font-semibold text-lg md:text-xs sm:text-xs'>{displayTZSCurrency(product?.productId?.sellingPrice * product?.quantity)}</p>
                                    </div>
                                    <div className='flex items-center gap-3 mt-1'>
                                        <button className='border bg-orange-400 text-white w-6 h-6 flex items-center justify-center rounded' onClick={() => decreaseQty(product?._id, product?.quantity)}>-</button>
                                        <span>{product?.quantity}</span>
                                        <button className='border bg-green-400 text-white w-6 h-6 flex items-center justify-center rounded' onClick={() => increaseQty(product?._id, product?.quantity)}>+</button>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
                {/* Cart Summary */}
                {cartData.length > 0 && (
                    <div className='mt-5 lg:mt-0 w-full max-w-md'>
                        <div className='h-36 bg-slate-200'>
                            <h2 className='text-white bg-orange-500 px-4 py-1 font-bold'>Summary</h2>
                            <div className='px-4 py-1 flex items-center justify-between'>
                                <p>Quantity</p>
                                <p>{totalQty}</p>
                            </div>
                            <div className='px-4 py-1 flex items-center justify-between'>
                                <p>Total Price</p>
                                <p>{displayTZSCurrency(totalPrice)}</p>
                            </div>
                            <button onClick={handlePayment} className='bg-green-500 text-white w-full p-2 font-bold hover:bg-orange-600' title='Click to Place Order'>Checkout</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Cart;
