import SummaryApi from "../common";
import { toast } from 'react-toastify';

const addToCart = async (e, productId, payload) => {
    e?.stopPropagation();
    e?.preventDefault();
  
    const requestBody = {
      productId,
      variant: payload, // Send variant or wholesale data
    };
  
    console.log("Request body:", requestBody); // Debugging: Log the request body
  
    const response = await fetch(SummaryApi.addToCartProduct.url, {
      method: SummaryApi.addToCartProduct.method,
      credentials: 'include',
      headers: {
        "content-type": 'application/json',
      },
      body: JSON.stringify(requestBody),
    });
  
    const responseData = await response.json();
  
    if (responseData.success) {
      toast.success(responseData.message);
    } else if (responseData.error) {
      toast.error(responseData.message);
    }
  
    return responseData;
  };
  

export default addToCart;
