import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SummaryApi from '../common'
import { FaStar } from "react-icons/fa";
import { FaStarHalf } from "react-icons/fa";
import displayTZSCurrency from '../helpers/DisplayCurrency';
import CategoryWiseProductDisplay from '../components/CategoryWiseProductDisplay';
import Context from '../context';
import addToCart from '../helpers/addToCart';
import { FaGreaterThanEqual, FaRegStarHalfStroke } from "react-icons/fa6";
import { toast } from 'react-toastify';

const ProductDetails = () => {
  const [data, setData] = useState({
    productName: "",
    brandName: "",
    category: "",
    productImage: [],
    description: "",
    price: "",
    sellingPrice: "",
    condition: "",
    variants: []
  });
  const productListImagesLoading = new Array(4).fill(null)
  const [selectedVariant, setSelectedVariant] = useState(null); // Track the selected variant
  const [selectedWholesale, setSelectedWholesale] = useState(null); // Track selected wholesale option
  const [selectedQuantity, setSelectedQuantity] = useState(1); // Track selected wholesale quantity
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [activeImage, setActiveImage] = useState("");
  const navigate = useNavigate();
  const { fetchUserAddToCart } = useContext(Context)

  

const handleAddToCart = async (e, id) => {
    // Prepare the payload for the selected option
    const payload =
      selectedVariant && Object.keys(selectedVariant).length > 0
        ? selectedVariant
        : selectedWholesale && Object.keys(selectedWholesale).length > 0
        ? { wholesaleOptions: [selectedWholesale] } // Wrap wholesale data in an array
        : null;
  
    // if (!payload) {
    //   toast.error("Please select a product variant or wholesale option before adding to cart.");
    //   return;
    // }
  
    await addToCart(e, id, payload);
    fetchUserAddToCart();
  };
  
  const handleBuyProduct = async (e, id) => {
    await addToCart(e, id, selectedVariant || selectedWholesale); // Pass selected variant or wholesale data
    fetchUserAddToCart()
    navigate("/cart")
  };

  const fetchProductDetails = async () => {
    setLoading(true);
    const response = await fetch(SummaryApi.productDetails.url, {
      method: SummaryApi.productDetails.method,
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({
        productId: params?.id
      })
    });
    setLoading(false);
    const dataResponse = await response.json();
    setData(dataResponse?.data);
    setActiveImage(dataResponse?.data?.productImage[0]);
  };

  useEffect(() => {
    fetchProductDetails();
  }, [params]);
  const handleMouseEnterProduct = (imageURL) => {
    setActiveImage(imageURL)

  }

  const [zoomImageCoordinate, setZoomImageCoordinate] = useState({
    x: 0,
    y: 0
  })
  const [zoomImage, setZoomImage] = useState(false)


  const handleZoomImage = useCallback((e) => {
    setZoomImage(true)
    const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
    console.log("coordinate", left, top, width, height);

    const x = (e.clientX - left) / width;
    const y = (e.clientY - top) / height;

    setZoomImageCoordinate({
      x,
      y
    });

  }, [setZoomImageCoordinate])

  const handleLeaveImageZoom = () => {
    setZoomImage(false)
  }

  const handleVariantChange = (variant) => {
    setSelectedVariant(variant);
    setSelectedWholesale(null); // Reset wholesale selection when selecting a regular variant
    setSelectedQuantity(1); // Reset selected quantity
  };

  const handleWholesaleChange = (option) => {
    setSelectedWholesale(option);
    setSelectedVariant(null); // Reset regular variant selection when selecting a wholesale option
    setSelectedQuantity(option.quantity); // Set selected quantity to minimum quantity for the wholesale option
  };

  // Calculate the displayed price based on selected option
  const displayedPrice = selectedWholesale
    ? selectedWholesale.wholesalePrice * selectedQuantity
    : selectedVariant
      ? selectedVariant.price
      : data.sellingPrice;

  return (
    <div className='container w-full max-w-[2560px] mx-auto p-4'>
      <div className='min-h-[200px] flex flex-col lg:flex-row gap-4'>
        {/* Product Image Section */}
        <div className='h-96 flex flex-col lg:flex-row-reverse gap-4'>

          <div className='relative bg-slate-200 h-[300px] w-[300px] lg:h-96 lg:w-96 h-96 w-96 p-2'>
            <img src={activeImage} className='h-full w-full object-scale-down mix-blend-multiply' onMouseMove={handleZoomImage} onMouseLeave={handleLeaveImageZoom} />
            {/* product image zoom */}

            {
              zoomImage && (
                <div className='hidden lg:block absolute min-w-[500px] overflow-hidden min-h-[460px] bg-slate-200 p-1 -right-[510px] top-0'>
                  <div
                    className='w-full h-full min-h-[400px] min-w-[400px] mix-blend-multiply scale-150'
                    style={{
                      backgroundImage: `url(${activeImage})`,
                      backgroundRepeat: `no-repeat`,
                      backgroundPosition: `${zoomImageCoordinate.x * 100}% ${zoomImageCoordinate.y * 100}%`
                    }}
                  >

                  </div>
                </div>
              )
            }

          </div>
          <div className='h-full'>
            {
              loading ? (
                <div className='flex gap-2 lg:flex-col overflow-scroll scrollbar-none h-full'>
                  {
                    productListImagesLoading?.map((el, index) => {
                      return (
                        <div className='h-20 w-20 animate-pulse bg-slate-200 rounded animate-pulse' key={"loadingImage" + index}>

                        </div>
                      )

                    })
                  }

                </div>
              ) : (
                <div className='flex gap-2 lg:flex-col overflow-scroll scrollbar-none h-full'>
                  {
                    data?.productImage?.map((imgURL, index) => {
                      return (
                        <div className='h-20 w-20 bg-slate-200 rounded p-1' key={imgURL}>
                          <img src={imgURL} alt='' className='w-full h-full object-scale-down mix-blend-multiply cursor-pointe' onMouseEnter={() => handleMouseEnterProduct(imgURL)} onClick={() => handleMouseEnterProduct(imgURL)} />
                        </div>
                      )

                    })
                  }

                </div>
              )
            }
          </div>


        </div>
        {
          loading ? (
            <div className='grid gap-1 w-full'>
              <p className='bg-slate-200 animate-pulse text-orange-600 h-6 lg:h-8 rounded-full inline-block w-full'></p>
              <h2 className='text-2xl lg:text-4xl font-medium h-6 lg:h-8 rounded-full animate-pulse bg-slate-200 w-full'></h2>
              <p className='capitalize text-slate-400 h-6 lg:h-8 rounded-full animate-pulse bg-slate-200 w-full'></p>
              <div className='text-red-600 flex items-center h-6 lg:h-8 rounded-full animate-pulse bg-slate-200 w-full gap-1'>


              </div>
              <div className='flex mt-2 items-center gap-2 my-1 w-full'>
                <p className=' text-2xl lg:text-3xl font-medium text-orange-600 h-6 lg:h-8 rounded-full animate-pulse bg-slate-200 w-full'>  </p>
                <p className='text-xl lg:text-2xl text-slate-500 font-medium line-through h-6 lg:h-8 rounded-full animate-pulse bg-slate-200 w-full'>  </p>

              </div>
              <div className='flex mt-2 items-center gap-3'>
                <button className='rounded px-3 py-1 min-w-[120px]  font-medium h-6 lg:h-8 rounded-full animate-pulse bg-slate-200 w-full'></button>
                <button className='rounded px-3 py-1 min-w-[120px] font-medium text-white h-6 lg:h-8 rounded-full animate-pulse bg-slate-200 w-full'></button>
              </div>
              <div className='my-2 w-full gap-2 mt-2'>
                <p className='h-6 lg:h-8 py-1 rounded-full animate-pulse bg-slate-200 w-full'></p>
                <p className='h-10 lg:h-12 py-1 mt-2 rounded-full animate-pulse bg-slate-200 w-full'></p>

              </div>


            </div>
          ) : (
            // {/* Product Details */ }
            < div className='flex flex-col gap-1'>
              <p className='bg-orange-200 text-orange-600 px-2 rounded-full inline-block w-fit'>{data?.brandName}</p>
              <h2 className='text-2xl lg:text-4xl font-medium'>{data?.productName}</h2>
              <div className='flex'>
                <p className='capitalize text-slate-400'>{data?.category}</p>
                <p className='capitalize px-4 text-slate-400'><b>Condition : </b>{data?.condition}</p>
              </div>
              <p className='capitalize py-5 text-black'><b>In Stock : </b>{selectedVariant ? selectedVariant.stock : data?.stock} Available</p>

              <div className='flex items-center gap-2 my-1'>
                <p className=' text-2xl lg:text-3xl font-medium text-orange-600'>
                  {displayTZSCurrency(displayedPrice)}
                </p>
                <p className='text-xl lg:text-2xl text-slate-500 font-medium line-through'>
                  {displayTZSCurrency(data.price)}
                </p>
              </div>

              {/* Regular Variant Selection */}
              {data.variants && data.variants.length > 0 && (
                <div className='my-4'>
                  <h3 className='text-lg font-semibold mb-2 bg-white text-slate-600 p-1'>Select Variant: (1 Quantity)</h3>
                  <div className='grid grid-cols-2 text-sm md:grid-cols-2 lg:grid-cols-3 gap-2'>
                    {data.variants
                      .filter(
                        variant =>
                          variant.color ||
                          variant.size ||
                          variant.specifications ||
                          variant.price ||
                          variant.stock
                      ) // Filter non-empty variants
                      .map((variant, index) => (
                        <button
                          key={index}
                          onClick={() => handleVariantChange(variant)}
                          className={`border rounded p-2 ${selectedVariant === variant
                              ? 'bg-orange-500 text-white'
                              : 'bg-gray-200 text-black'
                            } hover:bg-orange-500 hover:text-white`}
                        >
                          <div className='grid'>
                            <div className='flex flex-col gap-1'>
                              {variant.color && <p> <b>Color:</b>{variant.color} </p>}
                              {variant.size && <p><b>Size:</b> {variant.size} </p>}
                              {variant.specifications && (
                                <p><b>Specifications:</b> {variant.specifications} </p>
                              )}

                              {variant.price && (
                                <p> <b>Price: </b><b className='text-orange-600 hover:text-white'>{displayTZSCurrency(variant.price)}</b></p>
                              )}
                            </div>
                            {variant.stock && <p><b>Stock:</b> {variant.stock}</p>}
                          </div>
                        </button>
                      ))}
                  </div>
                </div>
              )}


              {/* Wholesale Options */}
              {data.variants && data.variants.some(variant => variant.wholesaleOptions && variant.wholesaleOptions.length > 0) && (
                <div className='my-4'>
                  <h3 className='text-lg text-slate-600 font-semibold mb-2 bg-white p-1'>Wholesale Options: (Multiple Quantities)</h3>
                  <div className='grid grid-cols-2 text-sm md:grid-cols-2 lg:grid-cols-3 gap-2'>

                    {data.variants
                      .filter(variant => variant.wholesaleOptions && variant.wholesaleOptions.length > 0)
                      .map((variant, index) =>
                        variant.wholesaleOptions.map((option, i) => (
                          <div>
                            <p>{variant.color}</p>
                            <button
                              key={`${index}-${i}`}
                              onClick={() => handleWholesaleChange(option)}
                              className={`border rounded p-2 ${selectedWholesale === option ? 'bg-orange-500 text-white' : 'bg-gray-200 text-black'} hover:bg-orange-500 hover:text-white`}
                            >
                              <div className='flex items-center gap-2'>
                                <p className='flex items-center text-sm lg:text-lg gap-1'>
                                  <FaGreaterThanEqual className='text-sm lg:text-lg' />
                                  <span className='text-sm lg:font-semibold'>{option.quantity}</span>
                                </p>
                                <p>=</p>
                                <p className='lg:text-lg text-sm lg:font-medium '>
                                  {displayTZSCurrency(option.wholesalePrice)}
                                </p>
                              </div>

                            </button>
                          </div>
                        ))
                      )}
                  </div>

                  {/* Quantity Selection for Wholesale */}
                  {selectedWholesale && (
                    <div className='my-4'>
                      <label className='block mb-1 font-semibold'>Select Quantity:</label>
                      <input
                        type='number'
                        min={selectedWholesale.quantity}
                        max={data.stock}
                        value={selectedQuantity}
                        onChange={(e) => setSelectedQuantity(Math.max(selectedWholesale.quantity, e.target.value))}
                        className='border p-2 rounded w-full'
                      />
                      <p className='mt-1 text-sm text-gray-500'>Minimum quantity: {selectedWholesale.quantity}</p>
                    </div>
                  )}
                </div>
              )}


              <div className='my-2'>
                <p className='text-slate-600 font-medium lg:text-xl my-1'>Description</p>
                <p>{data?.description}</p>
              </div>

              <div className=' flex items-center gap-3'>
                <button onClick={(e) => handleBuyProduct(e, data?._id)} className='border-2 border-red-600 rounded px-3 py-1 min-w-[120px] text-red-600 font-medium hover:bg-red-600 hover:text-white'>Buy</button>
                <button onClick={(e) => handleAddToCart(e, data?._id)} className='border-2 border-orange-500 rounded px-3 py-1 min-w-[120px] text-orange-500 font-medium text-white bg-orange-500 hover:bg-white hover:text-orange-500'>Add To Cart</button>
              </div>






            </div>
          )
        }
      </div>
      <div className='min-h-[200px] grid max-w-[300px] lg:max-w-[750px] p-4 mt-3'>
        <h1 className='text-xl lg:text-2xl font-medium border-b pb-1 border-slate-300'>Seller Information </h1>
        <p className='text-lg lg:text-xl font-medium'>Seller <b className='capitalize text-orange-600'>{data?.userId?.name}</b></p>
        <p className='text-lg lg:text-xl font-medium'>Email Address <b className='text-blue-600'><a href={`mailto:${data?.userId?.email}`}>{data?.userId?.email}</a></b></p>
        <p className='text-lg lg:text-xl font-medium'>Location <b className='capitalize text-orange-600'>{data?.userId?.location || 'Not Provided'}</b></p>

      </div>
       {
        data.category && (
          <CategoryWiseProductDisplay category={data?.category} heading={"Recommended Product"} />

        )
      }
    </div>
  );
};

export default ProductDetails;
